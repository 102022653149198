<template>
	<main class="page-aloe-vera" v-if="category">
		<category-banner
			v-if="category.banner"
			:banner="category.banner"
			:slides="category.carusel"
		/>
		<section class="section section--larger">
			<div class="container">
				<h2 class="section__title has-text-centered">
					Informații despre aloea noastră
				</h2>
				<div class="columns is-multiline">
					<div
						class="column is-6-tablet is-4-desktop"
						v-for="feature in category.caracteristici"
						:key="feature.id"
					>
						<div class="feature has-text-centered is-relative">
							<div
								class="feature__icon is-flex is-align-items-center is-justify-content-center"
							>
								<img
									:src="
										`${databaseLink}/${feature.imagine.url}`
									"
									:alt="feature.imagine.alternativeText"
								/>
							</div>
							<h3 class="feature__title mb-3">
								{{ feature.titlu }}
							</h3>
							<p class="feature__description">
								{{ feature.descriere }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<component-videos
			:videos="category.videoclipuri"
			v-if="category.videoclipuri && category.videoclipuri.length > 0"
		/>

		<testimonial-carousel />
		<component-gallery
			:images="images"
			v-if="
				category.galerie_imagini && category.galerie_imagini.length > 0
			"
		/>
		<section
			class="section section__products section--larger"
			v-if="highlightedProducts && highlightedProducts.length > 0"
		>
			<div class="container">
				<h2 class="section__title has-text-centered">
					Produse {{ category.titlu }}
				</h2>
				<div class="columns is-multiline">
					<div
						class=" column is-4 mb-5"
						v-for="product in highlightedProducts"
						:key="product.id"
					>
						<component-product :product="product" />
					</div>
				</div>
			</div>
		</section>
		<section
			class="section section__articles section--larger"
			v-if="highlightedArticles && highlightedArticles.length > 0"
		>
			<div class="container">
				<h2 class="section__title has-text-centered">
					Articole {{ category.titlu }}
				</h2>
				<div class="columns is-multiline">
					<div
						class="column is-4 mb-5"
						v-for="article in highlightedArticles"
						:key="article.id"
					>
						<component-article
							:article="article"
						></component-article>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>

<script>
	import CategoryBanner from '@/components/TheCategoryBanner.vue';
	import TestimonialCarousel from '@/components/TheTestimonialCarousel.vue';
	import ComponentProduct from '@/components/ComponentProduct.vue';
	import ComponentArticle from '@/components/ComponentArticle.vue';
	import ComponentVideos from '@/components/ComponentVideos.vue';
	import ComponentGallery from '@/components/ComponentGallery.vue';

	import { mapGetters } from 'vuex';

	export default {
		metaInfo() {
			return {
				title: `Forever Diamond Team - ${this.category.titlu}`,
			};
		},
		components: {
			CategoryBanner,
			TestimonialCarousel,
			ComponentProduct,
			ComponentArticle,
			ComponentVideos,
			ComponentGallery,
		},
		computed: {
			databaseLink() {
				return process.env.VUE_APP_STRAPI_DATABASE_URL;
			},
			...mapGetters('general', ['getCategoryByName']),
			category() {
				const routeName = this.$route.path.slice(
					this.$route.path.indexOf('/') + 1
				);
				return this.getCategoryByName(routeName)[0];
			},
			images() {
				return this.category.galerie_imagini.map(
					(img) =>
						`${process.env.VUE_APP_STRAPI_DATABASE_URL}/${img.imagine.url}`
				);
			},
			highlightedProducts() {
				return this.category.products.filter((prod) => {
					return prod.highlight_produs === true;
				});
			},
			highlightedArticles() {
				return this.category.articles.filter((art) => {
					return art.highlight_articol === true;
				});
			},
		},
	};
</script>

<style scoped lang="scss">
	.section {
		h2.section__title {
			margin-bottom: 8rem;
		}
	}
	.banner {
		&__title {
			.quote {
				color: darken($secondary, 10%);
				flex-wrap: wrap;
			}
		}
	}
	.column {
		margin-bottom: 4rem;
	}
	.feature {
		border: 1px solid rgba($yellow, 0.5);
		border-radius: $round-corners;
		padding: 5rem 1rem 1rem;
		height: 100%;
		transition: background-color 0.3s ease;
		&:hover {
			background-color: rgba($yellow, 0.5);
			.feature__icon {
				box-shadow: 0 8px 8px -2px rgba(0, 0, 0, 0.1);
			}
		}
		&__icon {
			background-color: $white;
			border: 1px solid rgba($yellow, 0.5);
			border-radius: 50%;
			box-sizing: content-box;
			line-height: 100px;
			position: absolute;
			left: 50%;
			top: 0;
			transform: translate(-50%, -50%);
			transition: box-shadow 0.3s ease;
			height: 120px;
			width: 120px;
		}
		&__title {
			font-size: 1.25rem;
			min-height: 52px;
			line-height: 1.3;
		}
	}
</style>
